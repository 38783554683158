<template>
    <div style="background-color: white;min-height: 650px;padding: 20px;box-sizing: border-box;">

        <div v-if="bannerList.length > 0" style="display:flex;height: 200px;gap: 20px;">
            <div v-for="(item, index) in bannerList" :key="index" style="cursor: pointer;"
                @click="doShowNewsInfo(item)">
                <img :src="item.image_url" style="width: 300px;height: 100%;border-radius: 5px;">
            </div>
        </div>

        <div style="margin-top: 20px;">
            <div v-for="(news, index) in dataList" :key="index" class="news-item" @click="doShowNewsInfo(news)">
                <div class="news_time">
                    <p> {{ $dayjs(news.create_time_time ? news.create_time * 1000 : news.create_time * 1000).format("MMM DD, YYYY") }}</p>
                </div>
                <div style="flex: 1;padding-left: 10px;box-sizing: border-box;">
                    <p class="news_title">{{ formatTitle(news) }}</p>
                    <p class="news_intro" v-html="news.post_content"></p>
                </div>
            </div>
        </div>
        <el-dialog :visible.sync="showNewsDialog" width="45%" :title="selectNews.post_title">
            <div style="margin: 10px">
                <p v-html="selectNews.post_content" style="color: #666; font-size: 14px; line-height: 20px; white-space: pre-wrap; word-break: keep-all;"></p>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    getOpenNewsUpdateList,
    postRequest
} from '../../api/eae'
import '../../assets/common/font.css'
export default ({
    name: 'index',
    data() {
        return {
            bannerList: [],
            dataList: [],
            showNewsDialog: false,
            selectNews: {}
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {

        doShowNewsInfo(news) {
            this.selectNews = news
            this.showNewsDialog = true
        },

        formatTitle(news) {
            let title = ''
            if (news.project_tags && news.project_tags.length > 0) {
                for (let i = 0; i < news.project_tags.length; i++) {
                    title += news.project_tags[i] + " "
                }
                return title + " | " + news.post_title
            } else {
                return news.post_title
            }
        },

        fetchData() {

            const loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });

            this.bannerList = []
            postRequest({function: 'getOpenMarketingPostList'}).then((res) => {
                let result = res.data
                result.map((item) => {
                    if (item.topped == 'YES') {
                        if (this.bannerList.length <= 3) {
                            this.bannerList.push(item)
                        }
                    }
                })
                this.dataList = result.sort(this.compare('create_time', false)).filter((item) => {
                    return !item.topped || item.topped == 'NO'
                })
                loading.close()
            })
        },
        compare(property, desc) {
            return function (a, b) {
                var value1 = a[property];
                var value2 = b[property];
                if (desc == true) {
                    // 升序排列
                    return value1 - value2;
                } else {
                    // 降序排列
                    return value2 - value1;
                }
            }
        }
    }
})
</script>
<style scoped lang="scss">
.news-item {
    cursor: pointer;
    width: 100%;
    border-radius: 5px;
    height: 132px;
    border: 1px solid #E6D7D7;
    padding: 20px 16px;
    box-sizing: border-box;
    display: flex;
    margin-bottom: 20px;

    .news_time {
        width: 120px;
        display: flex;
        align-items: center;
        color: #FC6556;
        font-size: 18px;
        font-family: centurygothic_bold;
    }

    .news_title {
        color: #333;
        font-size: 18px;
        font-family: centurygothic_bold;
    }

    .news_intro {
        margin-top: 10px;
        flex: 2;
        color: #666;
        font-size: 15px;
        line-height: 22px;
        font-family: centurygothic;

        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        text-overflow: ellipsis;
    }
}

.news-content {
    color: #666;
    font-size: 14px;
    line-height: 20px;
    white-space: pre-wrap;
    word-break: keep-all;
}
</style>